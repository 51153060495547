<template>
    <div :key="$route.fullPath">
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal" ></dotlottie-player>
            </template>
            <template v-slot:header>Hub</template>
            <iframe :src="baseUrl" width="100%" style="height:100vh"></iframe>
        </main-template>
    </div>
</template>

<script>
    import MainTemplate from "@/v3/templates/MainTemplate.vue";

    export default {
        components: {
            MainTemplate
        },
        props: {
            baseUrl: {
                type: String
            },
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
        methods:{
            getDarkLogo()
            {
                return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_dark"
            },
            getLightLogo()
            {
                return process.env.VUE_APP_API_URL + "/api/json/lottie?type=desk_light"
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
