<template>
    <div :key="$route.fullPath">
        <main-template>
            <template v-if="darkLogo" v-slot:headerLottieDark>
                <dotlottie-player :src="darkLogo"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-if="lightLogo" v-slot:headerLottieLight>
                <dotlottie-player :src="lightLogo"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>
                {{title ? title : 'Iframe'}}
            </template>
            <iframe :src="baseUrl" width="100%" style="height:100vh" class="-mb-12"></iframe>
        </main-template>
    </div>
</template>

<script>
    import MainTemplate from "@/v3/templates/MainTemplate";

    export default {
        components: {
            MainTemplate
        },
        props: {
            baseUrl: {
                type: String
            },
            title: {
                type: String,
                default: null
            },
            lightLogo:{
                type: String,
                default: null
            },
            darkLogo:{
                type: String,
                default: null
            }
        },
        computed: {
            linkName() {
                return null;
            },
            link(){
                return this.$route.fullPath.replace('/v3','')
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>
